import {
  ApolloClientPromoService,
  ApolloClientPromoUploadService,
} from '../../services/ApolloClientPromoService';
import promoRestService from '../../services/PromoRestService';
import {
  CREATE_UPDATE_TRACKER_BASIC_SETTINGS,
  GET_TRACKERS,
  PUBLISH_TRACKER,
  UPDATE_TRACKER_SCHEDULE,
  GET_TRACKER_DETAIL,
  UPDATE_TRACKER_RULES,
  UPDATE_TRACKER_CASES,
} from '../../gql/tracker';

export const createUpdateTrackerBasicSettings = async (input, trackerId) => {
  // must return all users and update
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: CREATE_UPDATE_TRACKER_BASIC_SETTINGS,
      variables: { input, trackerId },
    });

    return data.createAndUpdateBasicDetailsTracker;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const updateTrackerSchedule = async (input, trackerId) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: UPDATE_TRACKER_SCHEDULE,
      variables: { input, trackerId },
    });

    return data.updateTrackerSchedule;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const updateTrackerRules = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: UPDATE_TRACKER_RULES,
      variables: variables,
    });

    return data.updateTrackerRules;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const updateTrackerCases = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: UPDATE_TRACKER_CASES,
      variables: variables,
    });

    return data.updateTrackerCases;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const getTrackers = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_TRACKERS,
      fetchPolicy: 'network-only',
      variables: variables,
      errorPolicy: 'ignore',
    });
    return {
      success: true,
      error: false,
      data: data?.getTrackers,
    };
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: [],
    };
  }
};

export const getTrackerDetail = async ({ trackerId } = {}) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_TRACKER_DETAIL,
      fetchPolicy: 'no-cache',
      variables: { trackerId },
    });

    return data;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: [],
    };
  }
};

export const publishTracker = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: PUBLISH_TRACKER,
      variables: variables,
      fetchPolicy: 'network-only',
    });
    return {
      success: data?.publishTracker?.success,
      message: data?.publishTracker?.message,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed!. Please try again later.',
    };
  }
};

export const getPublishedTrackers = async (data) => {
  try {
    const result = await promoRestService?.post('tracker/publishedList', data);
    return result?.data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed!. Please try again later.',
    };
  }
};
