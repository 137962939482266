import React, { useEffect, useState } from 'react';
import { useLocation, Link, Outlet, useParams } from 'react-router-dom';

// reactstrap
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';
import TrackerBasicSettings from './TrackerBasicSettings';
import TrackerSchedule from './TrackerSchedule';
import TrackerRules from './TrackerRules';
import { alertError, alertSuccess } from '../../helpers/errorHandling';
import { useNavigate } from 'react-router-dom';
import { getTrackerDetail, publishTracker } from '../../actions/tracker';
import TrackerCases from './TrackerCases';

const TrackerDetail = ({ trackerIdFromPopup, isPopup = false }) => {
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [trackerDetail, setTrackerDetail] = useState({});
  const [activeTab, setActiveTab] = useState('0');
  const trackerParamsId = params?.id || trackerIdFromPopup;

  useEffect(() => {
    let selectedTab = '0';
    switch (pathname) {
      case `/trackers/detail/${params.id}/schedule`:
        selectedTab = '1';
        break;
      case `/trackers/detail/${params.id}/conditions`:
        selectedTab = '2';
        break;
      default:
        selectedTab = '0';
    }
    setActiveTab(selectedTab);
  }, [pathname]);

  const fetchTrackerDetail = async (trackerId) => {
    const result = await getTrackerDetail({ trackerId });
    setTrackerDetail(result.getTrackerDetail);
  };

  useEffect(() => {
    if (trackerParamsId !== 'new') {
      fetchTrackerDetail(trackerParamsId);
    }
  }, [trackerParamsId]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const publishTrackerFunc = async () => {
    let trackerId = trackerParamsId;
    const variables = {
      input: {
        trackerId: trackerId,
      },
    };

    const { success, message } = await publishTracker(variables);
    if (success) {
      navigate(`/trackers/detail/${trackerId}/basic`);
      fetchTrackerDetail(trackerId);
      alertSuccess(message);
    } else {
      alertError(message);
    }
  };

  const updateHandler = () => {
    fetchTrackerDetail(trackerParamsId);
  };
  const handleCancel = () => {
    navigate(`/trackers/list`);
  };
  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <Row className="align-items-center justify-content-between ps-4 pe-4 pt-4 pb-2">
            <Col xs="auto">
              {trackerDetail?.basicSettings?.trackerName ? (
                <h4 className="mb-sm-0 fw-medium text-uppercase fs-16">
                  {`Tracker Details - ${trackerDetail?.basicSettings?.trackerName}`}
                </h4>
              ) : (
                <h4 className="mb-sm-0 fw-medium text-uppercase fs-16">
                  Tracker Details
                </h4>
              )}
            </Col>
            <Col xs="auto">
              {trackerDetail?.status === 'updated' ? (
                <Button
                  size="md"
                  color="success"
                  className="ms-2 px-4"
                  onClick={() => publishTrackerFunc()}
                >
                  Publish
                </Button>
              ) : null}
            </Col>
          </Row>
          <Nav tabs className="nav  nav-tabs-custom rounded ">
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '0' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('0')}
                tag={Link}
                to={!isPopup && `/trackers/detail/${params.id}/basic`}
              >
                <i className="bx bx-user ms-2 me-2 fs-18"></i>
                Basic Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '1' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('1')}
                tag={Link}
                to={!isPopup && `/trackers/detail/${params.id}/schedule`}
              >
                <i className="bx bx-calendar-event ms-2 me-2 fs-18 "></i>
                Schedule
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '2' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('2')}
                tag={Link}
                to={!isPopup && `/trackers/detail/${params.id}/conditions`}
              >
                <i className="bx bx-info-circle ms-2 me-2 fs-18"></i>
                Conditions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '3' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('3')}
                tag={Link}
                to={!isPopup && `/trackers/detail/${params.id}/cases`}
              >
                <i className="bx bx-list-ul ms-2 me-2 fs-18"></i>
                Cases
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              {activeTab === '0' ? (
                <TrackerBasicSettings
                  trackerId={trackerParamsId}
                  trackerDetail={trackerDetail}
                  updateHandler={updateHandler}
                  onCancel={handleCancel}
                />
              ) : null}
              {activeTab === '1' ? (
                <TrackerSchedule
                  trackerId={trackerParamsId}
                  trackerDetail={trackerDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '2' ? (
                <TrackerRules
                  trackerId={trackerParamsId}
                  trackerDetail={trackerDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '3' ? (
                <TrackerCases
                  trackerId={trackerParamsId}
                  trackerDetail={trackerDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
            </TabPane>
          </TabContent>
        </Card>
      </Container>
    </div>
  );
};

export default TrackerDetail;
