import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  CardBody,
} from 'reactstrap';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { useEffect } from 'react';

const SegmentationBasicSettings = ({
  segmentDetail,
  onCancel,
  updateHandler,
}) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: segmentDetail?._id
      ? segmentDetail
      : {
          segmentName: '',
          segmentDescription: '',
          type: '',
          method: '',
          update: '',
          status: '',
          schedule: {
            startDate: '',
            endDate: '',
            applicableMonths: [],
            applicableWeekDays: [],
            applicableDaysOfMonths: [],
          },
        },
    validationSchema: Yup.object({
      segmentName: Yup.string().required('Segment Name is required'),
      segmentDescription: Yup.string().required('Description is required'),
      type: Yup.string().required('Type is required'),
      method: Yup.string().required('Method is required'),
      update: Yup.string().required('Update frequency is required'),
      status: Yup.string().required('Status is required'),
      schedule: Yup.object({
        startDate: Yup.string().required('Start Date is required'),
        endDate: Yup.string().required('End Date is required'),
      }),
    }),
    onSubmit: (values) => {
      updateHandler(values);
      //   navigate('/segmentation/rules'); // Navigate to the next tab (rules and partitions)
    },
  });
  const handleSetMultipleValues = (valuesToUpdate, setFieldValue) => {
    Object.entries(valuesToUpdate).forEach(([key, value]) => {
      formik.setFieldValue(key, value);
    });
  };
  useEffect(() => {
    if (segmentDetail?._id) {
      handleSetMultipleValues(segmentDetail);
    }
  }, [segmentDetail?._id]);

  return (
    <CardBody className="p-4">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              {/* Segment Name */}
              <FormGroup>
                <Label for="segmentName">Segment Name</Label>
                <Input
                  id="segmentName"
                  name="segmentName"
                  value={formik.values.segmentName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.segmentName && !!formik.errors.segmentName
                  }
                />
              </FormGroup>
              {formik.touched.segmentName && formik.errors.segmentName && (
                <div className="invalid-feedback">
                  {formik.errors.segmentName}
                </div>
              )}

              {/* Segment Description */}
              <FormGroup>
                <Label for="segmentDescription">Segment Description</Label>
                <Input
                  type="textarea"
                  id="segmentDescription"
                  name="segmentDescription"
                  value={formik.values.segmentDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                    formik.touched.segmentDescription &&
                    !!formik.errors.segmentDescription
                  }
                />
              </FormGroup>
              {formik.touched.segmentDescription &&
                formik.errors.segmentDescription && (
                  <div className="invalid-feedback">
                    {formik.errors.segmentDescription}
                  </div>
                )}

              {/* Type */}
              <FormGroup>
                <Label for="type">Type</Label>
                <Select
                  id="type"
                  name="type"
                  options={[
                    { value: 'scd', label: 'SCD' },
                    { value: 'manual', label: 'Manual' },
                  ]}
                  onChange={(selectedOption) =>
                    formik.setFieldValue('type', selectedOption.value)
                  }
                  value={{
                    value: formik.values.type,
                    label: formik.values.type,
                  }}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.type && !!formik.errors.type}
                />
              </FormGroup>
              {formik.touched.type && formik.errors.type && (
                <div className="invalid-feedback">{formik.errors.type}</div>
              )}

              {/* Method */}
              <FormGroup>
                <Label for="method">Method</Label>
                <Select
                  id="method"
                  name="method"
                  options={[
                    { value: 'conditions', label: 'Conditions' },
                    { value: 'targeted', label: 'Targeted' },
                  ]}
                  onChange={(selectedOption) =>
                    formik.setFieldValue('method', selectedOption.value)
                  }
                  value={{
                    value: formik.values.method,
                    label: formik.values.method,
                  }}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.method && !!formik.errors.method}
                />
              </FormGroup>
              {formik.touched.method && formik.errors.method && (
                <div className="invalid-feedback">{formik.errors.method}</div>
              )}

              {/* Update Frequency */}
              <FormGroup>
                <Label for="update">Update Frequency</Label>
                <Select
                  id="update"
                  name="update"
                  options={[
                    { value: 'daily', label: 'Daily' },
                    { value: 'weekly', label: 'Weekly' },
                    { value: 'monthly', label: 'Monthly' },
                    { value: 'manually', label: 'Manually' }, // Added manually option
                  ]}
                  onChange={(selectedOption) =>
                    formik.setFieldValue('update', selectedOption.value)
                  }
                  value={{
                    value: formik.values.update,
                    label: formik.values.update,
                  }}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.update && !!formik.errors.update}
                  className="custom-select" // Added custom class for styling
                />
              </FormGroup>
              {formik.touched.update && formik.errors.update && (
                <div className="invalid-feedback">{formik.errors.update}</div>
              )}

              {/* Status */}
              <FormGroup>
                <Label for="status">Status</Label>
                <Select
                  id="status1"
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                  onChange={(selectedOption) =>
                    formik.setFieldValue('status', selectedOption.value)
                  }
                  value={{
                    value: formik.values.status,
                    label: formik.values.status,
                  }}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.status && !!formik.errors.status}
                  className="custom-select" // Added custom class for styling
                />
              </FormGroup>
              {formik.touched.status && formik.errors.status && (
                <div className="invalid-feedback">{formik.errors.status}</div>
              )}

              {/* Schedule Start and End Date */}
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="scheduleStartDate">Start Date</Label>
                    <Input
                      type="date"
                      id="scheduleStartDate"
                      name="schedule.startDate"
                      value={formik.values.schedule.startDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.schedule?.startDate &&
                        !!formik.errors.schedule?.startDate
                      }
                    />
                  </FormGroup>
                  {formik.touched.schedule?.startDate &&
                    formik.errors.schedule?.startDate && (
                      <div className="invalid-feedback">
                        {formik.errors.schedule.startDate}
                      </div>
                    )}
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="scheduleEndDate">End Date</Label>
                    <Input
                      type="date"
                      id="scheduleEndDate"
                      name="schedule.endDate"
                      value={formik.values.schedule.endDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.schedule?.endDate &&
                        !!formik.errors.schedule?.endDate
                      }
                    />
                  </FormGroup>
                  {formik.touched.schedule?.endDate &&
                    formik.errors.schedule?.endDate && (
                      <div className="invalid-feedback">
                        {formik.errors.schedule.endDate}
                      </div>
                    )}
                </Col>
              </Row>

              <div className="d-flex justify-content-between">
                <Button type="button" onClick={onCancel} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Next: Rules & Partitions
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </FormikProvider>
    </CardBody>
  );
};

SegmentationBasicSettings.propTypes = {
  onCancel: PropTypes.func,
  updateHandler: PropTypes.func,
};

SegmentationBasicSettings.defaultProps = {
  onCancel: () => {},
  updateHandler: () => {},
};

export default SegmentationBasicSettings;
