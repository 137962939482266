import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material-ui
import {
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  ModalFooter,
  Button,
} from 'reactstrap';

// third-party
import { Form, FormikProvider, useFormik } from 'formik';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// project-imports
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import { updatePromotionProductEligibility } from '../../../actions/promotion/index';
import { convertFieldToQueryBuilderFormat } from '../../../helpers/convertFieldToQueryBuilderFormat';
import { QueryBuilderApp } from '../../Promotion/QueryBuilderApp';
import { v4 as uuidv4 } from 'uuid';
import { getAllFields } from '../../../actions/promotion/fields';
import { useDispatch } from 'react-redux';
import InnerLoader from '../../../components/Common/InnerLoader';
import { updateTrackerRules } from '../../../actions/tracker';

const discountTypes = [
  {
    label: 'Percentage',
    value: 'percentage',
  },
  {
    label: 'Fixed',
    value: 'fixed',
  },

  {
    label: 'Group',
    value: 'segmented',
  },
  {
    label: 'Group - Unit Based',
    value: 'unitBased',
  },
  {
    label: 'Combo',
    value: 'combo',
  },
  {
    label: 'Gift',
    value: 'gift',
  },
];

const rewardTypes = [
  {
    label: 'Fixed',
    value: 'fixed',
  },
  {
    label: 'Formula',
    value: 'rewardFormula',
  },
];

const walletTypes = [
  {
    label: 'Formula',
    value: 'walletFormula',
  },
  {
    label: 'Fixed',
    value: 'fixed',
  },
];

const TrackerRules = ({
  onCancel,
  getStores = () => {},
  trackerId,
  trackerDetail,
  updateHandler = () => {},
}) => {
  const navigate = useNavigate();

  const { schedule, basicSettings } = trackerDetail || { schedule: null };

  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //
  // }, []);

  const [trackerRulesQuery, setTrackerRulesQuery] = useState({
    combinator: 'and',
    rules: [],
  });
  const dispatch = useDispatch();

  const getFields = async () => {
    const { data } = await getAllFields(
      {
        pageOffset: Math.max(0),
        pageSize: 2000,
      },
      dispatch
    );
    // setFields(convertFieldToQueryBuilderFormat(data));
  };

  useEffect(() => {
    if (trackerDetail) {
      setTrackerRulesQuery({
        ...trackerDetail?.trackerRules,
      });
      getFields();
    }
  }, [trackerDetail]);

  const updateTrackerRulesFunc = async () => {
    setIsLoading(true);

    const variables = {
      trackerId,
      input: { trackerRules: trackerRulesQuery },
    };

    const { data, success } = await updateTrackerRules(variables);

    if (success) {
      updateHandler();
      alertSuccess('Tracker Rules updated successfully.');
    } else {
      alertError('Failed!, Please try after some time.');
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const onDragEnd = async (result) => {
    if (!result?.destination) {
      return;
    }
  };

  // const duplicateRule = (tIndex) => {
  //   const newTiers = [...tiers];
  //   newTiers?.splice(tIndex + 1, 0, {
  //     ...newTiers?.[tIndex],
  //     id: generateId(),
  //   });
  //   setTiers([...newTiers]);
  // };

  const generateId = () => {
    return uuidv4();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (trackerId) {
          updateTrackerRulesFunc();
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleTrackerRules = (rules) => {
    setTrackerRulesQuery(rules);
  };

  const { errors, touched, handleSubmit, isSubmitting } = formik;

  return (
    <CardBody className="p-4">
      <Row columnSpacing={2}>
        <Col lg={7} md={12} className="position-relative">
          <DragDropContext onDragEnd={onDragEnd}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Card className="shadow-none">
                  <CardHeader className="p-0 border-0">
                    <CardTitle className="fs-14 fw-medium mb-0 ">
                      Tracker Rules
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="px-0">
                    <Row>
                      <QueryBuilderApp
                        data={{ ...trackerRulesQuery }}
                        updateQuery={handleTrackerRules}
                        fieldGroups={['item']}
                      />
                    </Row>
                  </CardBody>
                </Card>
                <ModalFooter className="sticky-modal-footer">
                  <hr />
                  <div className="d-flex gap-2 justify-content-end">
                    <Button outline color="danger" size="md" onClick={onCancel}>
                      Cancel
                    </Button>

                    <Button
                      loading={false}
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      size="md"
                      className="bg-gradient px-5"
                    >
                      {trackerDetail?.trackerName
                        ? 'Save Draft'
                        : 'Save & Next'}
                    </Button>
                  </div>
                </ModalFooter>
              </Form>
            </FormikProvider>
          </DragDropContext>

          {isLoading ? <InnerLoader /> : null}
        </Col>
      </Row>
    </CardBody>
  );
};

TrackerRules.propTypes = {
  tracker: PropTypes.any,
  onCancel: PropTypes.func,
  updateHandler: PropTypes.func,
};

export default TrackerRules;
