import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import InnerLoader from '../../../components/Common/InnerLoader';
import { getSegments } from '../../../actions/promotion/segment';

const SegmentTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [segments, setSegments] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const getSegmentsFunc = async () => {
    try {
      setIsLoading(true);
      const data = { pageOffset, pageSize };
      const res = await getSegments(data);

      setTotalPages(res?.data?.totalPages || 1);
      setSegments(res?.data?.segments || []);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSegmentsFunc();
  }, [pageOffset, pageSize]);

  const pages = [...Array(totalPages)?.keys()];

  return (
    <>
      <div className="table-responsive table-card">
        <table className="table table-nowrap table-centered align-middle mb-0">
          <thead className="bg-light text-muted">
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Type</th>
              <th>Method</th>
              <th>Update</th>
              <th>Partitions</th>
              <th>Rules</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(segments || []).map((segment, key) => (
              <tr key={key}>
                <td>
                  <div className="d-flex align-items-center">
                    <h5 className="fs-13 mb-1">{segment?.segmentName}</h5>
                  </div>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <h5 className="fs-13 mb-1">
                      {segment?.segmentDescription}
                    </h5>
                  </div>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <h5 className="fs-13 mb-1">{segment?.type}</h5>
                  </div>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <h5 className="fs-13 mb-1">{segment?.method}</h5>
                  </div>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <h5 className="fs-13 mb-1">{segment?.update}</h5>
                  </div>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <h5 className="fs-13 mb-1">
                      {segment?.partitions?.map((partition, index) => (
                        <span key={index}>
                          <strong>{partition.field}:</strong>{' '}
                          {Array.isArray(partition.value)
                            ? partition.value
                                .map((v) =>
                                  typeof v === 'object'
                                    ? `${v.min}-${v.max}`
                                    : v
                                )
                                .join(', ')
                            : partition.value}
                          <br />
                        </span>
                      ))}
                    </h5>
                  </div>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <h5 className="fs-13 mb-1">
                      {segment?.rules && segment.rules.length > 0 ? (
                        <>
                          <strong>Combinator: </strong> {segment.combinator}{' '}
                          <br />
                          {segment.rules.map((rule, index) => (
                            <div key={index}>
                              <strong>{rule.field}:</strong> {rule.operator}{' '}
                              {rule.value} <br />
                              {rule.errorMessage && (
                                <small>{rule.errorMessage}</small>
                              )}
                            </div>
                          ))}
                        </>
                      ) : (
                        <span>No rules defined</span>
                      )}
                    </h5>
                  </div>
                </td>

                <td>
                  <div className="d-flex flex-row gap-3">
                    <Link
                      className="btn btn-primary"
                      to={`/promotions/segmentation/detail/${segment._id}`}
                    >
                      View
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
          <div className="col-sm"></div>
          <div className="col-sm-auto mt-3 mt-sm-0">
            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
              <li className={`page-item ${pageOffset < 1 ? 'disabled' : ''}`}>
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => setPageOffset(pageOffset - 1)}
                >
                  ←
                </Link>
              </li>
              {pages.map((page, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    pageOffset === index ? 'active' : ''
                  }`}
                >
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => setPageOffset(index)}
                  >
                    {index + 1}
                  </Link>
                </li>
              ))}
              <li
                className={`page-item ${
                  pageOffset >= totalPages - 1 ? 'disabled' : ''
                }`}
              >
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => setPageOffset(pageOffset + 1)}
                >
                  →
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}

      {isLoading && <InnerLoader />}
    </>
  );
};

export default SegmentTable;
