import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Widgets from './Widgets';
import { useDispatch, useSelector } from 'react-redux';
import Section from './Section';
import RecentTransactionTable from './RecentTransactionTable';
import RevenueChart from './RevenueChart';
import { useParams } from 'react-router-dom';
import { getRewardTypeDetails } from '../../../actions/rewards';

const RewardDashboard = () => {
  document.title = 'Welcome - Home Page ';
  const params = useParams();
  const { dashboard } = useSelector((state) => state?.dashboard || {});
  const { widgets } = dashboard || {};
  const [rewardDetails, setRewardDetails] = useState(null);
  const [country, setCountry] = useState('ae');

  useEffect(() => {
    if (params?.path) {
      getRewardTypeDetailsFunc(params?.path);
    }
  }, [params?.path]);

  const getRewardTypeDetailsFunc = async (id) => {
    try {
      const { success, data } = await getRewardTypeDetails({
        rewardTypeId: id,
      });

      if (success) {
        setRewardDetails(data);
      } else {
        setRewardDetails(null);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row className="mb-0">
            <Col xs={12}>
              <Section rewardDetails={rewardDetails} country={country} />
            </Col>
          </Row>

          {/* Main */}
          <Row>
            <Col xxl={12}>
              <Widgets rewardDetails={rewardDetails} country={country} />
            </Col>
          </Row>
          <Row>
            <Col xxl={6}>
              <RecentTransactionTable
                rewardDetails={rewardDetails}
                country={country}
              />
            </Col>
            <Col xxl={6}>
              <RevenueChart rewardDetails={rewardDetails} country={country} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RewardDashboard;
