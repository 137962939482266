import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { alertSuccess, alertError } from '../../helpers/errorHandling';
import { updateTrackerCases } from '../../actions/tracker';

const TrackerCases = ({
  trackerDetail,
  trackerId,
  updateHandler = () => {},
  onCancel = () => {},
}) => {
  const [cases, setCases] = useState(trackerDetail?.trackerCases || []);
  const [newCase, setNewCase] = useState({
    id: uuidv4(),
    caseName: '',
    caseRule: {
      aggregator: '', // Replacing trackedEntity with aggregator
      operator: '', // Changed operator to dropdown
      value: '',
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in newCase.caseRule) {
      setNewCase((prev) => ({
        ...prev,
        caseRule: {
          ...prev.caseRule,
          [name]: value,
        },
      }));
    } else {
      setNewCase((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const addCase = () => {
    if (
      !newCase.caseName ||
      !newCase.caseRule.aggregator ||
      !newCase.caseRule.operator ||
      !newCase.caseRule.value
    ) {
      alertError('All fields are required.');
      return;
    }

    setCases((prevCases) => [...prevCases, newCase]);
    setNewCase({
      id: uuidv4(),
      caseName: '',
      caseRule: {
        aggregator: '',
        operator: '',
        value: '',
      },
    });
    alertSuccess('Case added successfully.');
  };

  const removeCase = (id) => {
    setCases((prevCases) => prevCases.filter((caseItem) => caseItem.id !== id));
  };

  const saveCases = async () => {
    try {
      //   trackerDetails.trackerCases = cases; // Updating the trackerDetails
      const variables = {
        trackerId,
        trackerCases: cases,
      };
      const {
        success: updateCasesSuccess,
        message: createMessage,
        error: gqError,
      } = await updateTrackerCases(variables);
      if (updateCasesSuccess && trackerId) {
        updateHandler(trackerDetails); // Callback to save changes
        alertSuccess('Cases saved successfully.');
      }
    } catch (error) {
      alertError('Failed to save cases.');
    }
  };

  return (
    <Card>
      <CardHeader>
        <h5 className="mb-0">Manage Tracker Cases</h5>
      </CardHeader>
      <CardBody>
        {/* Add New Case Form */}
        <Row className="mb-4">
          <Col md={6}>
            <Label for="caseName">Case Name</Label>
            <Input
              id="caseName"
              name="caseName"
              type="text"
              placeholder="Enter Case Name"
              value={newCase.caseName}
              onChange={handleInputChange}
            />
          </Col>
          <Col md={6}>
            <Label for="aggregator">Aggregator</Label>
            <Input
              id="aggregator"
              name="aggregator"
              type="select"
              value={newCase.caseRule.aggregator}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select Aggregator
              </option>
              <option value="SUM">SUM</option>
              <option value="COUNT">COUNT</option>
            </Input>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={6}>
            <Label for="operator">Operator</Label>
            <Input
              id="operator"
              name="operator"
              type="select"
              value={newCase.caseRule.operator}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select Operator
              </option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value=">">&gt;</option>
              <option value=">=">&ge;</option>
              <option value="<=">&le;</option>
              <option value="!=">&ne;</option>
            </Input>
          </Col>
          <Col md={6}>
            <Label for="value">Value</Label>
            <Input
              id="value"
              name="value"
              type="text"
              placeholder="Enter Value"
              value={newCase.caseRule.value}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <Button color="primary" onClick={addCase}>
          Add Case
        </Button>

        {/* Display List of Cases */}
        <h6 className="mt-4">Cases List</h6>
        {cases.length > 0 ? (
          cases.map((caseItem, index) => (
            <div key={caseItem.id} className="border p-3 mb-2">
              <Row>
                <Col>
                  <p className="mb-1">
                    <strong>Case Name:</strong> {caseItem.caseName}
                  </p>
                  <p className="mb-1">
                    <strong>Aggregator:</strong> {caseItem.caseRule.aggregator}
                  </p>
                  <p className="mb-1">
                    <strong>Operator:</strong> {caseItem.caseRule.operator}
                  </p>
                  <p>
                    <strong>Value:</strong> {caseItem.caseRule.value}
                  </p>
                </Col>
                <Col md="auto">
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => removeCase(caseItem.id)}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            </div>
          ))
        ) : (
          <p>No cases added yet.</p>
        )}

        {/* Save & Cancel Buttons */}
        <div className="mt-4 d-flex justify-content-end gap-2">
          <Button color="danger" outline onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" onClick={saveCases}>
            Save Cases
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

// PropTypes
TrackerCases.propTypes = {
  trackerDetails: PropTypes.shape({
    cases: PropTypes.arrayOf(
      PropTypes.shape({
        caseName: PropTypes.string,
        caseRule: PropTypes.shape({
          aggregator: PropTypes.string,
          operator: PropTypes.string,
          value: PropTypes.string,
        }),
      })
    ),
  }),
  updateHandler: PropTypes.func,
  onCancel: PropTypes.func,
};

export default TrackerCases;
