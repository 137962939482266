import { gql } from '@apollo/client';

const trackerDetailsOutput = `
_id
basicSettings {
  trackerName
  trackedEntity
  trackerDescription
  country
}
schedule {
  startDate
  endDate
  neverExpires
  applicableMonths
  applicableWeekDays
  applicableDaysOfMonths
}
trackerRules
trackerCases {
  id
  caseName
  caseRule {
    aggregator
    operator
    value
  }
}
status`;

export const GET_TRACKERS = gql`
  query getTrackers($input: TrackersInput!) {
    getTrackers(input: $input) {
      totalPages
      totalTrackers
      currentOffset
      trackers {
        ${trackerDetailsOutput}
      }
    }
  }
`;

export const GET_TRACKER_DETAIL = gql`
  query getTrackerDetail($trackerId: String!) {
    getTrackerDetail(trackerId: $trackerId) {
      ${trackerDetailsOutput}
    }
  }
`;

export const CREATE_UPDATE_TRACKER_BASIC_SETTINGS = gql`
  mutation createAndUpdateBasicDetailsTracker(
    $input: TrackerInputBasicSettings!
    $trackerId: String
  ) {
    createAndUpdateBasicDetailsTracker(input: $input, trackerId: $trackerId) {
      id
      success
      message
    }
  }
`;

export const UPDATE_TRACKER_SCHEDULE = gql`
  mutation updateTrackerSchedule(
    $input: TrackerInputSchedule!
    $trackerId: String!
  ) {
    updateTrackerSchedule(input: $input, trackerId: $trackerId) {
      id
      success
      message
    }
  }
`;

export const PUBLISH_TRACKER = gql`
  mutation publishTracker($input: PublishTrackerInput!) {
    publishTracker(input: $input) {
      id
      success
      message
    }
  }
`;

export const UPDATE_TRACKER_RULES = gql`
  mutation updateTrackerRules($trackerId: String!, $input: TrackerRulesInput!) {
    updateTrackerRules(trackerId: $trackerId, input: $input) {
      id
      success
      message
    }
  }
`;

export const UPDATE_TRACKER_CASES = gql`
  mutation updateTrackerCases(
    $trackerId: String!
    $trackerCases: [TrackerCasesInput!]!
  ) {
    updateTrackerCases(trackerId: $trackerId, trackerCases: $trackerCases) {
      id
      success
      message
    }
  }
`;
