import React, { useEffect, useState } from 'react';
import { useLocation, Link, Outlet, useParams } from 'react-router-dom';
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import SegmentationBasicSettings from './SegmentationBasicSettings';
import SegmentationConditions from './SegmentationConditions';
import {
  createOrUpdateSegment,
  getSegmentDetail,
  updateConditionsAndPartitions,
} from '../../../actions/promotion/segment';
import { toast } from 'react-toastify';
const SegmentationDetail = ({ segmentIdFromPopup, isPopup = false }) => {
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [segmentDetail, setSegmentDetail] = useState({});
  const [activeTab, setActiveTab] = useState('0');
  const segmentParamsId = params?.id || segmentIdFromPopup;

  useEffect(() => {
    let selectedTab = '0';
    switch (pathname) {
      case `/promotions/segmentation/detail/${params.id}/basic`:
        selectedTab = '1';
        break;
      case `/promotions/segmentation/detail/${params.id}/conditions`:
        selectedTab = '2';
        break;
      default:
        selectedTab = '0';
    }
    setActiveTab(selectedTab);
  }, [pathname]);
  const getSegmentsFunc = async () => {
    const result = await getSegmentDetail({ id: segmentParamsId });
    setSegmentDetail(result.data?.segment);
  };

  useEffect(() => {
    if (segmentParamsId && segmentParamsId !== 'new') {
      getSegmentsFunc();
    }
  }, [segmentParamsId]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const updateHandlerBasicDetails = async (data) => {
    try {
      const result = await createOrUpdateSegment(data);
      if (result?.data?._id) {
        // navigate(
        //   `/promotions/segmentation/detail/${result?.data?._id}/conditions`
        // );
        setActiveTab('2');
      }
    } catch (e) {
      toast.error(e?.message);
      console.error(e);
    }
    // alert(data);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <Row className="align-items-center justify-content-between ps-4 pe-4 pt-4 pb-2">
            <Col xs="auto">
              {segmentDetail?.segmentName ? (
                <h4 className="mb-sm-0 fw-medium text-uppercase fs-16">
                  {`${segmentDetail?.segmentName}`}
                </h4>
              ) : (
                <h4 className="mb-sm-0 fw-medium text-uppercase fs-16">
                  Segmentation Details
                </h4>
              )}
            </Col>
          </Row>
          <Nav tabs className="nav  nav-tabs-custom rounded ">
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '0' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('0')}
                tag={Link}
                to={
                  !isPopup &&
                  `/promotions/segmentation/detail/${segmentParamsId || 'new'}`
                }
              >
                <i className="bx bx-user ms-2 me-2 fs-18"></i>
                Basic Details
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={`${
                  activeTab === '2' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('2')}
                tag={Link}
              >
                <i className="bx bx-info-circle ms-2 me-2 fs-18"></i>
                Conditions
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              {activeTab === '0' ? (
                <SegmentationBasicSettings
                  segmentId={segmentParamsId}
                  segmentDetail={segmentDetail}
                  updateHandler={updateHandlerBasicDetails}
                />
              ) : null}

              {activeTab === '2' ? (
                <SegmentationConditions
                  segmentId={segmentParamsId}
                  segmentDetail={segmentDetail}
                />
              ) : null}
            </TabPane>
          </TabContent>
        </Card>
      </Container>
    </div>
  );
};

export default SegmentationDetail;
