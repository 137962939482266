import React from 'react';
import { Case, Default, Else, If, Switch, Then, When } from 'react-if';

const operatorsMap = {
  '=': 'equals',
  '!=': 'does not equal',
  '<': 'is less than',
  '>': 'is greater than',
  '<=': 'is less than or equal to',
  '>=': 'is greater than or equal to',
  contains: 'contains',
  beginsWith: 'begins with',
  endsWith: 'ends with',
  doesNotContain: 'does not contain',
  doesNotBeginWith: 'does not begin with',
  doesNotEndWith: 'does not end with',
  null: 'is null',
  notNull: 'is not null',
  in: 'is in',
  notIn: 'is not in',
  between: 'is between',
  notBetween: 'is not between',
};

const RuleText = ({
  operation,
  onEditToggle,
  fieldsMap,
  memberConfigFieldsMap = {},
}) => {
  const { field, operator, value, computeField } = operation;
  const mergedFieldMaps = { ...memberConfigFieldsMap, ...fieldsMap };

  const getReadableText = () => {
    const readableOperator = operatorsMap[operator];
    if (!readableOperator) {
      return 'Invalid operator';
    }

    if (operator === 'null' || operator === 'notNull') {
      return `${field} ${readableOperator}`;
    }

    return (
      <>
        <div className="d-flex gap-2 align-items-center ">
          <b className="border rounded bg-white px-1 py-0 fs-12 fw-normal text-dark">
            {mergedFieldMaps[field]?.label}{' '}
          </b>
          <If condition={field?.indexOf(':') > -1}>
            <b className="border rounded bg-white px-1 py-0 fs-12 fw-normal text-dark">
              {mergedFieldMaps[computeField]?.label}
            </b>
          </If>
          <i className="fs-12 text-muted">{readableOperator}</i>{' '}
          <Switch>
            <Case condition={operator === 'between'}>
              <>
                <b className="border rounded bg-white px-1 py-0 fs-12 fw-normal text-dark">
                  {value[0] || ''}
                </b>
                and
                <b className="border rounded bg-white px-1 py-0 fs-12 fw-normal text-dark">
                  {value[1] || ''}
                </b>
              </>
            </Case>
            <Case condition={operator === 'in' || operator === 'notIn'}>
              {operator === 'in' || operator === 'notIn' ? (
                <>
                  // {console.log('operatoroperatoroperator', operator, value)}
                  {value?.map &&
                    value?.map((val, index) => (
                      <b
                        key={index}
                        className="border rounded bg-white px-1 py-0 fs-12 fw-normal text-dark"
                      >
                        {val}
                      </b>
                    ))}
                </>
              ) : null}
            </Case>
            <Default>
              <b className="border rounded bg-white px-1 py-0 fs-12 fw-normal text-dark">
                {value}
              </b>
            </Default>
          </Switch>
        </div>
      </>
    );
  };

  return (
    <div
      className="d-flex gap-2 align-items-center custom-space"
      onClick={onEditToggle}
    >
      {getReadableText()}
      <span onClick={onEditToggle} className="text-decoration-underline fs-12 ">
        Edit
      </span>
    </div>
  );
};
export default RuleText;
