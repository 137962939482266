import PropTypes from 'prop-types';
import { useNavigate, useOutletContext } from 'react-router-dom';

// reactstrap
import {
  Button,
  FormGroup,
  FormText,
  Col,
  Row,
  Label,
  Form,
  CardBody,
  Input,
  ModalFooter,
} from 'reactstrap';
import { DatePicker } from 'reactstrap-date-picker';
//Import Flatepicker
import Flatpickr from 'react-flatpickr';
// third-party
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { alertError, alertSuccess } from '../../helpers/errorHandling';
import { If } from 'react-if';
import { updateTrackerSchedule } from '../../actions/tracker';

const TrackerSchedule = ({
  onCancel = () => {},
  trackerId,
  trackerDetail,
  updateHandler = () => {},
}) => {
  const navigate = useNavigate();

  const StoreSchema = Yup.object().shape({
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date(),
    neverExpires: Yup.boolean(),
  });

  const { schedule } = trackerDetail || { schedule: null };

  const initialValues = {
    startDate: schedule?.startDate ? new Date(schedule?.startDate) : undefined,
    endDate: schedule?.endDate ? new Date(schedule?.endDate) : undefined,
    neverExpires: schedule?.neverExpires || false,
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let success;
      let message;
      let error;
      if (trackerId) {
        const inputData = {
          ...values,
        };

        const {
          success: createStoreSuccess,
          message: createMessage,
          error: gqError,
        } = await updateTrackerSchedule(values, trackerId);

        success = createStoreSuccess;
        if (success && trackerId) {
          navigate(`/trackers/detail/${trackerId}/conditions`);
          updateHandler();
        }
        error = gqError;
      }
      if (success) {
        alertSuccess('Tracker Schedule updated successfully.');
      } else {
        alertError('Failed!. Please try again later..');
      }

      setSubmitting(false);
      onCancel();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CardBody className="p-4">
      <Formik
        initialValues={initialValues}
        validationSchema={StoreSchema}
        onSubmit={handleSubmit}
        f
      >
        {({
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
        }) => (
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Row>
              <Col md={5}>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="startDate">Start Date</Label>

                      <Field name="startDate">
                        {({ field }) => (
                          // <DatePicker
                          //   id="startDate"
                          //   {...field}
                          //   value={field.value || ''}
                          //   onChange={(value) =>
                          //     setFieldValue('startDate', value)
                          //   }
                          //   invalid={touched?.startDate && !!errors?.startDate}
                          // />
                          <Flatpickr
                            className="form-control"
                            id="startDate"
                            value={field.value || ''}
                            onChange={(values) =>
                              setFieldValue('startDate', values?.[0])
                            }
                            invalid={touched?.startDate && !!errors?.startDate}
                            options={{
                              enableTime: true,
                              // dateFormat: 'Y-m-d H:i',
                              dateFormat: 'Y-m-d h:i K',
                              time_24hr: false,
                            }}
                          />
                        )}
                      </Field>
                      {touched?.startDate && errors?.startDate && (
                        <FormText color="danger">{errors?.startDate}</FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg={1}>
                    <span>&nbsp;→&nbsp;</span>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="endDate">End Date</Label>
                      <If condition={!values.neverExpires}>
                        <Field name="endDate">
                          {({ field }) => (
                            <Flatpickr
                              className="form-control"
                              id="endDate"
                              value={field.value || ''}
                              onChange={(values) => {
                                setFieldValue('endDate', values?.[0]);
                              }}
                              invalid={touched.endDate && !!errors.endDate}
                              options={{
                                enableTime: true,
                                // dateFormat: 'Y-m-d H:i',
                                dateFormat: 'Y-m-d h:i K',
                                time_24hr: false,
                              }}
                            />
                          )}
                        </Field>
                      </If>
                      <Field name="neverExpires">
                        {({ field }) => (
                          <>
                            <div className="form-check form-switch form-switch-custom form-switch-dark mb-2 d-flex gap-2 justify-content-center align-items-center p-0">
                              <Label
                                className="form-check-label"
                                htmlFor="neverExpires"
                              >
                                Never Expire
                              </Label>
                              <Input
                                className="form-check-input m-0"
                                type="checkbox"
                                role="switch"
                                id="neverExpires"
                                checked={field.value === true}
                                onChange={(values) => {
                                  setFieldValue('neverExpires', !field.value);
                                }}
                              />
                            </div>

                            {touched.endDate && errors.endDate && (
                              <FormText color="danger">
                                {errors.endDate}
                              </FormText>
                            )}
                          </>
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </CardBody>
  );
};

TrackerSchedule.propTypes = {
  tracker: PropTypes.any,
  onCancel: PropTypes.func,
  trackerId: PropTypes.any,
  trackerDetail: PropTypes.any,
  updateHandler: PropTypes.func,
};

export default TrackerSchedule;
