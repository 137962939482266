import { defaultOperators, toFullOption } from 'react-querybuilder';

export const convertFieldToQueryBuilderFormat = (fields) => {
  return fields
    .map((field) => {
      return {
        ...field,
        name: `${field.fieldGroup}.${field.fieldCode}`,
        label: field.fieldName, //
        placeholder: field.placeholder,
        errorMessages: field.errorMessages,
        group: field.fieldGroup,
        defaultValue: field.defaultValue || null, //
        operators: defaultOperators.filter((opt) => {
          if (field.dataType === 'number')
            return [
              '=',
              '!=',
              '>',
              '>=',
              '<',
              '<=',
              'between',
              'notBetween',
            ].includes(opt.name);
          return true;
        }), //defaultOperators.filter((op) => op.name === 'in'),
        // validator,
        valueEditorType:
          field?.fieldType === 'datetime' ? 'datetime-local' : field?.fieldType,
        values:
          field?.optionValues?.map((opt) => {
            return { name: opt.value, label: opt.label };
          }) || [],
      };
    })
    .map((o) => toFullOption(o));
};

export const convertMemberFieldsToQueryBuilderFormat = (configs) => {
  return configs
    .map((config) => {
      let fieldName = '';
      let fieldLabel = '';
      config.values.map((val) => {
        if (val.fieldCode === 'configLabel') {
          fieldLabel = val.fieldValue;
        } else if (val.fieldCode === 'fieldId') {
          fieldName = val.fieldValue;
        }
      });
      return {
        ...config,
        name: `member.${fieldName}`,
        label: fieldLabel, //
        // placeholder: field.placeholder,
        // errorMessages: field.errorMessages,
        group: 'member',
        // defaultValue: field.defaultValue || null, //
        operators: defaultOperators.filter((opt) => {
          if (config.dataType === 'number')
            return [
              '=',
              '!=',
              '>',
              '>=',
              '<',
              '<=',
              'between',
              'notBetween',
            ].includes(opt.name);
          return true;
        }), //defaultOperators.filter((op) => op.name === 'in'),
        // validator,
        valueEditorType:
          config?.fieldType === 'datetime'
            ? 'datetime-local'
            : config?.fieldType,
        values:
          config?.optionValues?.map((opt) => {
            return { name: opt.value, label: opt.label };
          }) || [],
      };
    })
    .map((o) => toFullOption(o));
};
