import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  ButtonGroup,
} from 'reactstrap';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';
import { registerPlugin } from 'react-filepond';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import './style.scss';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import QueryBuilderApp from '../../Promotion/QueryBuilderApp';

import {
  createOrUpdateRewardTiers,
  getRewardTiers,
} from '../../../actions/rewards/tier';
import { getAllFields } from '../../../actions/promotion/fields';

const RewardTierForm = ({
  submitHandler,
  reward = null,
  rewardTypeId = null,
}) => {
  const [selectedTierIndex, setSelectedTierIndex] = useState(0);
  const [isAddNewTier, setIsAddNewTier] = useState(false);
  const dispatch = useDispatch();

  const getFields = async () => {
    await getAllFields(
      {
        pageOffset: 0,
        pageSize: 100,
      },
      dispatch
    );
  };

  const initialValues = {
    tiers: [
      {
        rewardTypeId: reward?.rewardTypeId,
        tierName: '',
        tierRule: {
          rule: { combinator: 'and', rules: [] },
        },
      },
    ],
  };

  const validationSchema = Yup.object({
    tiers: Yup.array()
      .min(1, 'At least one tier is required')
      .of(
        Yup.object({
          rewardTypeId: Yup.string().required('Reward TypeId is required'),
          tierName: Yup.string().required('Tier Name is required'),
          tierRule: Yup.object({
            rule: Yup.object().required('Rule is required'),
          }),
        })
      ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isAddNewTier) {
          setSelectedTierIndex(values?.tiers?.length || 0);
          setFieldValue('tiers', [
            ...values?.tiers,
            {
              tierName: '',
              rewardTypeId: reward?.rewardTypeId,
              tierRule: {
                rule: { combinator: 'and', rules: [] },
              },
            },
          ]);

          return;
        } else {
          const inputData = { ...values };
          const { success, message } = await createOrUpdateRewardTiers(
            inputData
          );

          if (success) {
            alertSuccess(message);
            submitHandler();
            getRewardTiersFunc();
          } else {
            alertError({ message });
          }
        }

        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    values,
    isSubmitting,
    setFieldValue,
  } = formik;

  const getRewardTiersFunc = async () => {
    const inputData = {
      pageSize: 10,
      pageOffset: 0,
      rewardTypeId: rewardTypeId,
    };
    const { data, success } = await getRewardTiers(inputData);
    if (success && data?.tiers?.length > 0) {
      setFieldValue('tiers', data?.tiers);
      setSelectedTierIndex(0);
    } else {
      setFieldValue('tiers', initialValues?.tiers);
    }
  };

  useEffect(() => {
    getRewardTiersFunc();
  }, [rewardTypeId]);

  useEffect(() => {
    getFields();
  }, []);

  const onDragEnd = async (result) => {
    try {
      if (!result?.destination) {
        return;
      }
      const reorderedTiers = [...values?.tiers];
      const [removed] = reorderedTiers?.splice(result?.source?.index, 1);
      reorderedTiers?.splice(result?.destination?.index, 0, removed);
      setFieldValue('tiers', [...reorderedTiers]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {values?.tiers?.length > 0 && values?.tiers?.[0]?.tierName ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppable"
            type="priority"
            direction="horizontal"
          >
            {(provided) => (
              <div
                {...provided?.droppableProps}
                ref={provided?.innerRef}
                className="d-flex flex-row btn-group mb-4"
              >
                {values?.tiers?.map((tier, tierIndex) => {
                  return (
                    tier?.tierName && (
                      <Draggable
                        key={`tier-${tierIndex}`}
                        draggableId={`tier-${tierIndex}`}
                        index={tierIndex}
                      >
                        {(provided) => (
                          <div
                            ref={provided?.innerRef}
                            {...provided?.draggableProps}
                            {...provided?.dragHandleProps}
                            className="position-relative "
                          >
                            <div
                              className={`btn ${
                                selectedTierIndex === tierIndex
                                  ? 'btn-primary'
                                  : 'btn-light'
                              }  btn-sm px-4`}
                              onClick={() => {
                                setSelectedTierIndex(tierIndex);
                                setValues({ ...values });
                              }}
                              size="sm"
                            >
                              {tier?.tierName}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
      <div className="reward-type-form">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            {values?.tiers?.map(
              (tier, tierIndex) =>
                tierIndex === selectedTierIndex && (
                  <div key={tierIndex}>
                    <FormGroup>
                      <Label htmlFor={`tiers.${tierIndex}.tierName`}>
                        Tier Name
                      </Label>
                      <Input
                        id={`tiers.${tierIndex}.tierName`}
                        name={`tiers.${tierIndex}.tierName`}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tiers?.[tierIndex]?.tierName}
                        invalid={
                          touched?.tiers?.[tierIndex]?.tierName &&
                          !!errors?.tiers?.[tierIndex]?.tierName
                        }
                      />
                      <FormFeedback>
                        {errors?.tiers?.[tierIndex]?.tierName}
                      </FormFeedback>
                    </FormGroup>
                    <Card className="shadow-none border">
                      <CardHeader>
                        <Row>
                          <Col className="d-flex align-items-center gap-4">
                            <div className="d-flex align-items-center gap-2">
                              {/* <i className="bx bx-grid-vertical ms-2 me-2 fs-18"></i> */}
                              <span variant="subtitle2" display="block">
                                {`Rule`}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody className="border-bottom">
                        <FormGroup>
                          <Label>Conditions</Label>
                          <QueryBuilderApp
                            updateQuery={(value) =>
                              setFieldValue(
                                `tiers.${tierIndex}.tierRule.rule`,
                                value
                              )
                            }
                            data={values?.tiers?.[tierIndex]?.tierRule?.rule}
                            // isUseRewardTierRuleGroup={true}
                            fieldGroups={['user', 'reward']}
                          />
                          {touched?.tiers?.[tierIndex]?.tierRule?.rule &&
                          !!errors?.tiers?.[tierIndex]?.tierRule?.rule ? (
                            <FormFeedback>
                              {errors?.tiers?.[tierIndex]?.tierRule?.rule}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </CardBody>
                    </Card>

                    <hr />
                    <div className="d-flex gap-2 justify-content-end">
                      <Button
                        outline
                        color="danger"
                        size="md"
                        onClick={(e) => {
                          e?.stopPropagation();
                          setValues({ ...initialValues });
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        loading={false}
                        color="primary"
                        size="md"
                        className="bg-gradient px-5"
                        disabled={isSubmitting}
                        onClick={() => {
                          setIsAddNewTier(true);
                        }}
                        type="submit"
                      >
                        Add One More Tier
                      </Button>

                      <Button
                        loading={false}
                        type="submit"
                        color="primary"
                        size="md"
                        className="bg-gradient px-5"
                        disabled={isSubmitting}
                        onClick={() => {
                          setIsAddNewTier(false);
                        }}
                      >
                        Save & Next
                      </Button>
                    </div>
                  </div>
                )
            )}
          </Form>
        </FormikProvider>

        {isSubmitting ? <InnerLoader /> : null}
      </div>
    </>
  );
};

export default RewardTierForm;
