import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { getTrackers } from '../../actions/tracker';
import { formatDate } from '../../helpers/formatDate';
import { formatTime } from '../../helpers/formatTime';
import InnerLoader from '../../components/Common/InnerLoader';

import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Progress,
} from 'reactstrap';
import TrackerList from './TrackerList';
import TrackerDetail from './TrackerDetail';
import { If } from 'react-if';
import { isValidUUID } from '../../helpers/isValidUUID';
const TrackerTable = ({
  selectedTag = null,
  selectedStatus = null,
  selectedCountry = null,
  searchKey = '',
  startDate = null,
  endDate = null,
  selectedSortBy = null,
}) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [showTrackerDetail, setTrackerDetailOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [storeDeleteId, setStoreDeleteId] = useState('');
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getTrackersFunc = async () => {
    setLoading(true);

    const variables = {
      input: {
        pageOffset: pageOffset,
        pageSize: pageSize,
        filters: {
          status: selectedStatus?.value ? [selectedStatus?.value] : [],
          country: selectedCountry?.value ? [selectedCountry?.value] : [],
          trackerName: !isValidUUID(searchKey) ? searchKey : null,
          trackerIds: isValidUUID(searchKey) ? [searchKey] : [],
          startDate: startDate,
          endDate: endDate,
        },
        sortBy: selectedSortBy?.value,
      },
    };

    const { data } = await getTrackers(variables);

    setTimeout(() => {
      setData(data?.trackers || []);
      setTotalPages(data?.totalPages);
      setLoading(false);
    }, 400);
  };

  useEffect(() => {
    getTrackersFunc();
  }, [pageOffset]);

  useEffect(() => {
    if (pageOffset === 0) {
      getTrackersFunc();
    } else {
      setPageOffset(0);
    }
  }, [selectedCountry, selectedStatus, searchKey, endDate, selectedSortBy]);

  const pages = [...Array(totalPages)?.keys()];

  document.title = 'Tracker List';

  //

  return (
    <>
      <Modal
        size="xxl"
        className="modal-right"
        isOpen={showTrackerDetail}
        toggle={() => {
          window.history?.back();
          setTrackerDetailOpen(!showTrackerDetail);
        }}
      >
        {/* <ModalHeader toggle={() => setOpen(!open)}>Delete Customer</ModalHeader> */}
        <ModalBody>
          <If condition={showTrackerDetail}>
            <div style={{ overflowY: 'scroll' }}>
              <TrackerDetail trackerIdFromPopup={showTrackerDetail} isPopup />
            </div>
          </If>
        </ModalBody>
      </Modal>
      <div className="table-responsive table-card p-3">
        {(data || []).map((item, key) => (
          <div className="border rounded p-3 mb-2" key={`promo-${key}`}>
            <Row>
              <Col md={9} className="border-end d-flex gap-2">
                <div className="m-0">
                  <span
                    className="d-flex bg-soft-secondary p-2 rounded-2 opacity-25"
                    style={{ fontSize: 42 }}
                  >
                    <i className="bx bxs-discount text-secondary"></i>
                  </span>
                </div>
                <div className="m-0 w-100">
                  <div className="d-flex flex-wrap justify-content-between mb-1 gap-2">
                    <h5 className="m-0 fs-14 text-body d-flex flex-wrap align-items-center gap-2">
                      <a href={`/trackers/detail/${item?._id}/basic`}>
                        {item?.basicSettings?.trackerName}
                      </a>
                    </h5>
                  </div>
                  <div className="d-flex gap-1 justify-content-between">
                    <div className="m-0">
                      {item?.basicSettings?.country?.length ? (
                        <p className="d-flex flex-wrap align-items-center mb-1 gap-1 fs-11 text-muted mb-1 fw-normal">
                          Country:
                          <span className="d-flex flex-wrap align-items-center gap-1">
                            {item?.basicSettings?.country?.map(
                              (country, index) => (
                                <span
                                  key={`c-${index}`}
                                  className={`badge rounded-pill text-success bg-soft-success`}
                                >
                                  {country}
                                </span>
                              )
                            )}
                          </span>
                        </p>
                      ) : null}

                      <div className="d-flex flex-wrap gap-1 mb-1">
                        <p className="m-0 d-flex flex-wrap align-items-center">
                          <span className="fs-11 text-muted">
                            Tracker Entity:{' '}
                            <strong>
                              {item.basicSettings?.trackerEntity || '-'}
                            </strong>
                          </span>
                        </p>
                      </div>
                      <div className="d-flex flex-wrap align-items-center gap-1 mb-1">
                        <p className="m-0 fs-11 text-muted fw-normal d-flex flex-wrap gap-1">
                          Start Date :
                          <strong>
                            {item?.schedule?.startDate
                              ? formatDate(item?.schedule?.startDate)
                              : '-'}
                          </strong>
                        </p>
                        <span className="fs-12 text-muted">|</span>
                        <p className="m-0 fs-11 text-muted fw-normal d-flex flex-wrap gap-1">
                          End Date :
                          <strong>
                            {item?.schedule?.endDate
                              ? formatDate(item?.schedule?.endDate)
                              : '-'}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <Link
                      onClick={() => {
                        // window.location.href = `#detail/${item._id}`;
                        setTrackerDetailOpen(item._id);
                      }}
                      to={`/trackers/detail/${item?._id}/basic`}
                      className="fs-10 d-flex align-items-center mt-auto"
                    >
                      View Details <i className="bx bx-chevron-right fs-22"></i>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex flex-wrap flex-column justify-content-between gap-1 h-100 position-relative">
                  <p className="fs-11 text-muted mb-0 fw-normal d-flex flex-wrap flex-column align-items-start gap-1 h-100">
                    <span
                      className={`mt-auto fs-10 mb-1 fw-medium fs-13 px-2 badge badge-soft-success`}
                    >
                      {item.status || 'Active'}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm"></div>
        {totalPages > 1 ? (
          <div className="col-sm-auto mt-3 mt-sm-0">
            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
              <li className={`page-item  ${pageOffset <= 1 ? 'disabled' : ''}`}>
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    setPageOffset(pageOffset - 1);
                  }}
                >
                  ←
                </Link>
              </li>

              {pages?.map((page, index) => {
                return (
                  <li
                    className={`page-item ${
                      pageOffset === index ? 'active' : ''
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => setPageOffset(index)}
                    >
                      {index + 1}
                    </Link>
                  </li>
                );
              })}

              <li className="page-item">
                <Link
                  to="#"
                  className={`page-link ${
                    pageOffset >= totalPages ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    setPageOffset(pageOffset + 1);
                  }}
                >
                  →
                </Link>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
      {loading ? <InnerLoader /> : null}
    </>
  );
};

export default TrackerTable;
