import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  CardBody,
} from 'reactstrap';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import QueryBuilderApp from '../QueryBuilderApp';
import { updateConditionsAndPartitions } from '../../../actions/promotion/segment';
import { toast } from 'react-toastify';
import { getAllFields } from '../../../actions/promotion/fields';

const SegmentationConditions = ({ segmentDetail = {}, onCancel }) => {
  const [rules, setRules] = useState(segmentDetail?.rules || []);
  const [partitions, setPartitions] = useState(segmentDetail?.partitions || []);

  const updateHandlerConditions = async (data) => {
    try {
      const result = await updateConditionsAndPartitions({
        _id: segmentDetail._id,
        rules,
        partitions,
      });
    } catch (e) {
      toast.error(e?.message);
      console.error(e);
    }
    // alert(data);
  };

  const formik = useFormik({
    initialValues: {
      combinator: 'or',
      rules: rules,
      partitions: partitions,
    },
    // validationSchema: Yup.object({
    //   combinator: Yup.string().required('Combinator is required'),
    //   rules: Yup.array().min(1, 'At least one rule is required'),
    //   partitions: Yup.array().min(1, 'At least one partition is required'),
    // }),
    onSubmit: (values) => {
      updateHandlerConditions(values);
    },
  });

  const handleAddPartition = () => {
    setPartitions([...partitions, { field: '', type: '', value: '' }]);
  };
  useEffect(() => {
    if (segmentDetail?.rule) {
      setRules(segmentDetail?.rules);
    }
  }, [segmentDetail?.rules]);

  return (
    <CardBody className="p-4">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="rules">Rules</Label>
                <QueryBuilderApp
                  updateQuery={setRules}
                  data={rules}
                  fieldGroups={['member']}
                />
                {/* <Button type="button" onClick={handleAddRule} color="secondary">
                                    Add Rule
                                </Button> */}
              </FormGroup>

              <FormGroup>
                <Label for="partitions">Partitions</Label>
                <QueryBuilderApp />
                {/* <Button type="button" onClick={handleAddPartition} color="secondary">
                                    Add Partition
                                </Button> */}
              </FormGroup>

              <div className="d-flex justify-content-between">
                <Button type="button" onClick={onCancel} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </FormikProvider>
    </CardBody>
  );
};

SegmentationConditions.propTypes = {
  segmentDetail: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

SegmentationConditions.defaultProps = {
  segmentDetail: {},
  onCancel: () => {},
  onSave: () => {},
};

export default SegmentationConditions;
