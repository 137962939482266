import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  CardBody,
  ModalFooter,
} from 'reactstrap';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

import { alertError, alertSuccess } from '../../helpers/errorHandling';
import { createUpdateTrackerBasicSettings } from '../../actions/tracker';

export const COUNTRIES = ['OMAN', 'BAHRAIN', 'QATAR', 'UAE'];

const TrackerBasicSettings = ({
  trackerId,
  trackerDetail = null,
  onCancel = () => {},
  updateHandler = () => {},
}) => {
  const isCreating = false;
  const navigate = useNavigate();

  const StoreSchema = Yup.object().shape({
    trackerName: Yup.string()
      .max(100, 'Tracker Name must be at most 100 characters')
      .min(10, 'Tracker Name must be at least 10 characters')
      .required('Tracker Name is required')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Only letters, numbers, and spaces are allowed'
      ),
    trackedEntity: Yup.string().max(100).min(5),
    trackerDescription: Yup.string().max(500).min(5),
    country: Yup.array()
      .min(1, 'At least 1 country is required')
      .required('Country is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      trackerName: trackerDetail?.basicSettings?.trackerName || '',
      trackedEntity: trackerDetail?.basicSettings?.trackedEntity || '',
      trackerDescription:
        trackerDetail?.basicSettings?.trackerDescription || '',
      country: trackerDetail?.basicSettings?.country || [],
    },

    validationSchema: StoreSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let success;
        let message;
        let error;
        if (trackerId) {
          const inputData = {
            ...values,
          };
          const {
            success: createStoreSuccess,
            message: createMessage,
            error: gqError,
          } = await createUpdateTrackerBasicSettings(values, trackerId);
          success = createStoreSuccess;
          if (success && trackerId) {
            navigate(`/trackers/detail/${trackerId}/schedule`);
          }
          error = gqError;
        } else {
          const {
            success: createStoreSuccess,
            id,
            message: createMessage,
            error: gqError,
          } = await createUpdateTrackerBasicSettings(values);
          success = createStoreSuccess;
          if (success && id) {
            navigate(`/trackers/detail/${id}/schedule`);
          }
          error = gqError;
        }
        if (success) {
          updateHandler();
          alertSuccess(
            isCreating
              ? 'Tracker Basic Settings created successfully.'
              : 'Tracker Basic Settings updated successfully.'
          );
        } else {
          alertError('Failed!. Please try again later..');
        }

        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values: { country },
  } = formik;

  return (
    <CardBody className="p-4">
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="tracker-name">Tracker Name</Label>
                    <Input
                      id="tracker-name"
                      name="trackerName"
                      placeholder="Enter Tracker Name"
                      {...getFieldProps('trackerName')}
                      invalid={
                        touched.trackerName && Boolean(errors.trackerName)
                      }
                    />
                    <FormFeedback>
                      {touched.trackerName && errors.trackerName}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="tracker-description">Tracker Description</Label>
                    <Input
                      type="textarea"
                      id="tracker-description"
                      placeholder="Enter Tracker Description"
                      {...getFieldProps('trackerDescription')}
                      invalid={
                        touched.trackerDescription &&
                        Boolean(errors.trackerDescription)
                      }
                    />
                    <FormFeedback>
                      {touched.trackerDescription && errors.trackerDescription}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="tracked-entity">Tracked Entity</Label>
                    <Select
                      id="tracked-entity"
                      options={[
                        { value: 'finalValue', label: 'Final Value' },
                        { value: 'subTotal', label: 'Sub Total' },
                        { value: 'totalDiscount', label: 'Total Discount' },
                      ]}
                      value={{
                        value: formik.values.trackedEntity,
                        label: formik.values.trackedEntity,
                      }}
                      onChange={(selected) => {
                        setFieldValue('trackedEntity', selected.value);
                      }}
                      classNamePrefix="select"
                      invalid={
                        touched.trackedEntity && Boolean(errors.trackedEntity)
                      }
                    />
                    {touched.trackedEntity && errors.trackedEntity && (
                      <FormFeedback className="d-block">
                        {errors.trackedEntity}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="country">Choose Countries</Label>
                    <Select
                      id="country"
                      isMulti
                      options={COUNTRIES?.map((country) => ({
                        value: country,
                        label: country,
                      }))}
                      value={country?.map((country) => ({
                        value: country,
                        label: country,
                      }))}
                      onChange={(selected) => {
                        const selectedValues = selected
                          ? selected.map((item) => item.value)
                          : [];
                        setFieldValue('country', selectedValues);
                      }}
                      classNamePrefix="select"
                      invalid={touched.country && Boolean(errors.country)}
                    />
                    {touched?.country && errors?.country && (
                      <FormFeedback className="d-block">
                        {errors?.country}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalFooter className="sticky-modal-footer">
            <hr />
            <div className="d-flex gap-2 justify-content-end">
              <Button outline color="danger" size="md" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                loading={false}
                type="submit"
                color="primary"
                disabled={isSubmitting}
                size="md"
                className="bg-gradient px-5"
              >
                {trackerDetail?.trackerName ? 'Save Draft' : 'Save & Next'}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </FormikProvider>
    </CardBody>
  );
};

TrackerBasicSettings.propTypes = {
  tracker: PropTypes.any,
  onCancel: PropTypes.func,
  updateHandler: PropTypes.func,
};

export default TrackerBasicSettings;
