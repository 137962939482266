import promoRestService from '../../services/PromoRestService';

// Function to create or update a segment
export const createOrUpdateSegment = async (data) => {
  try {
    const result = await promoRestService?.post('segment/create', data);
    return result?.data;
  } catch (error) {
    console.error('Error in createOrUpdateSegment:', error);
    throw error;
  }
};
// Function to create or update a segment
export const updateConditionsAndPartitions = async (data) => {
  try {
    const result = await promoRestService?.post(
      'segment/updateContitionsAndPartitions',
      data
    );
    return result?.data;
  } catch (error) {
    console.error('Error in createOrUpdateSegment:', error);
    throw error;
  }
};

// Function to get list of segments
export const getSegments = async (data) => {
  try {
    const result = await promoRestService?.post('segment/list', data);
    return result?.data;
  } catch (error) {
    console.error('Error in getSegments:', error);
    return null;
  }
};

// Function to get list of segments
export const getSegmentDetail = async (data) => {
  try {
    const result = await promoRestService?.post('segment/detail', data);
    return result?.data;
  } catch (error) {
    console.error('Error in getSegments:', error);
    return null;
  }
};
