import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../components/Common/BreadCrumb';
import TrackerTable from './TrackerTable';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';

const promotionStatusOptions = [
  { value: '', label: 'All' },
  { value: 'published', label: 'Published' },
  { value: 'draft', label: 'Draft' },
  { value: 'updated', label: 'Updated' },
  { value: 'created', label: 'Created' },
];

const promotionTagsOptions = [
  { value: '', label: 'All' },
  { value: 'Points', label: 'Points' },
  { value: 'Transactional', label: 'Transactional' },
];

const countryOptions = [
  { value: '', label: 'All' },
  { value: 'UAE', label: 'UAE' },
  { value: 'BAHRAIN', label: 'Bahrain' },
  { value: 'OMAN', label: 'Oman' },
  { value: 'QATAR', label: 'Qatar' },
];

const sortByOptions = [
  {
    label: 'Default',
    value: null,
  },
  {
    label: 'Start Date (asc)',
    value: {
      key: 'schedule.startDate',
      order: 'asc',
    },
  },
  {
    label: 'Start Date (desc)',
    value: {
      key: 'schedule.startDate',
      order: 'desc',
    },
  },
  {
    label: 'End Date (asc)',
    value: {
      key: 'schedule.endDate',
      order: 'asc',
    },
  },
  {
    label: 'End Date (desc)',
    value: {
      key: 'schedule.endDate',
      order: 'desc',
    },
  },
  {
    label: 'Creation Date (asc)',
    value: {
      key: 'createdAt',
      order: 'asc',
    },
  },
  {
    label: 'Creation Date (desc)',
    value: {
      key: 'createdAt',
      order: 'desc',
    },
  },
];

const Tracker = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedSortBy, setSelectedSortBy] = useState(null);

  const resetFilter = () => {
    setSelectedStatus(null);
    setSelectedCountry(null);
    setSelectedTag(null);
    setSearchKey('');
    setStartDate(null);
    setEndDate(null);
    setDateRange('');
    setSelectedSortBy(null);
  };

  const formatRange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const sDate = selectedDates[0].toISOString().split('T')[0];
      const eDate = selectedDates[1].toISOString().split('T')[0];
      setStartDate(sDate);
      setEndDate(eDate);
      return `${sDate} to ${eDate}`;
    } else {
      setStartDate(null);
      setEndDate(null);
    }
    return '';
  };

  const handleDateChange = (selectedDates) => {
    // Normalize dates to remove any time component
    const normalizedDates = selectedDates?.map(
      (date) =>
        new Date(
          Date.UTC(date?.getFullYear(), date?.getMonth(), date?.getDate())
        )
    );
    setDateRange(formatRange(normalizedDates));
  };

  document.title = 'Trackers';
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Admin'} titleActive="Trackers" />
        <Card>
          <CardHeader className="border-0">
            <Row className="g-4 align-items-center">
              <Col sm></Col>
              <Col sm="auto">
                <div className="d-flex flex-wrap align-items-start gap-2">
                  {/* add user */}
                  <button
                    className="btn btn-success add-btn"
                    onClick={() => {
                      navigate(`/trackers/detail/new`);
                    }}
                  >
                    <i className="ri-user-fill me-1 align-bottom"></i> Create
                    new tracker
                  </button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          {/* Filters and search */}
          <CardBody className="border border border-dashed card-body">
            <div className="d-flex flex-row gap-2 flex-wrap">
              <div
                className="search-box d-inline-block"
                style={{ maxWidth: 450, minWidth: 450 }}
              >
                <Input
                  className="form-control search"
                  placeholder="Search by Tracker Id / Tracker Name"
                  type="text"
                  value={searchKey ?? ''}
                  onChange={(event) => setSearchKey(event?.target?.value || '')}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>

              <div>
                <Flatpickr
                  className="form-control"
                  style={{ width: 200 }}
                  options={{
                    mode: 'range',
                    dateFormat: 'Y-m-d',
                    onChange: handleDateChange,
                  }}
                  value={dateRange}
                  placeholder="Start Date to End Date"
                />
              </div>

              <div>
                <Select
                  placeholder="Select Status"
                  options={promotionStatusOptions}
                  value={selectedStatus}
                  onChange={(option) => setSelectedStatus(option)}
                />
              </div>

              <div>
                <Select
                  placeholder="Select Country"
                  options={countryOptions}
                  value={selectedCountry}
                  onChange={(option) => setSelectedCountry(option)}
                />
              </div>

              <div>
                <Select
                  placeholder="Sort By"
                  options={sortByOptions}
                  value={selectedSortBy}
                  onChange={(option) => setSelectedSortBy(option)}
                />
              </div>

              <Button
                color="light"
                outline
                className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center border-dark-subtle"
                style={{ height: 38 }}
                onClick={() => resetFilter()}
              >
                <i className="mdi mdi-restore fs-18 text-dark"></i>
              </Button>
            </div>
          </CardBody>
          <CardBody>
            <TrackerTable
              selectedCountry={selectedCountry}
              selectedStatus={selectedStatus}
              searchKey={searchKey}
              startDate={startDate}
              endDate={endDate}
              selectedSortBy={selectedSortBy}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Tracker;
